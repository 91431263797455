import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import ProtectedRoute from './ProtectedRoute';
import CallbackComponent from './CallbackComponent';
import Protected from './protected';
import S2SAuthComponent from './S2SAuthComponent';  // Assuming you create this component
import PrivacyPage from './privacy';  // Assuming you create this component

import './App.css';


function App() {
  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Protected />} />
        <Route path="/auth/discord/callback" element={<CallbackComponent />} />
        <Route path="/s2s/auth" element={<S2SAuthComponent />} />
        <Route path="/" element={<ProtectedRoute />} />
        <Route path="/privacy-policy" element={<PrivacyPage />} />
        
      </Routes>
    </Router>
  );
}

export default App;
