import React from 'react';

const PrivacyPage = () => {
    return (
      <div style={styles.container}>
        {/* Header at the Top */}
      <div className="header" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '80px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#0a2644',
        borderBottom: '1px solid #000000',
        zIndex: 1000,
      }}>
        {/* First Frame */}
        <div className="header-frame" style={{
          backgroundColor: '#223f99',
          height: '40px',
          borderRadius: '10px',
          width: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          fontFamily: 'system-ui, sans-serif',
          fontSize: '13px',
        }}>
          Adjust/S2S Converter by W_Ducky
        </div>
        
        {/* Second Frame with Small Spacing */}
        <a href="/" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
          >
            Home
          </a>
          <a href="mailto:contact@grindrobux.com" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
          >
            Contact
          </a>
          
      </div>
        <div style={styles.policyContent} >
          <h1 style={styles.title}>Privacy Policy for Adjust.lol</h1>
          <p style={styles.paragraph}>
            At Adjust.lol, we are committed to safeguarding your privacy. This Privacy Policy outlines our practices regarding the collection, use, and sharing of your information when you visit our website, particularly in relation to our use of Google AdSense and other third-party advertising services.
          </p>
  
          <h2 style={styles.subtitle}>Information We Collect</h2>
          <p style={styles.paragraph}>
            We collect various types of information to enhance your user experience and provide relevant content and advertisements. The information collected includes:
          </p>
          <ul style={styles.list}>
            <li style={styles.listItem}><span style={styles.bullet}>•</span><strong>Personal Identification Information</strong>: This includes your IP address and Discord account link.</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span><strong>Technical Data</strong>: Information such as browser type and version, device details, and operating system information.</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span><strong>Usage Data</strong>: Details regarding the pages you visit on our site and the duration of your visits.</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span><strong>Referring URLs</strong>: URLs of the websites you accessed before and after visiting our site.</li>
          </ul>
  
          <h2 style={styles.subtitle}>Use of Information</h2>
          <p style={styles.paragraph}>
            The information we collect is utilized for the following purposes:
          </p>
          <ul style={styles.list}>
            <li style={styles.listItem}><span style={styles.bullet}>•</span>To personalize your user experience.</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span>To analyze site usage and improve our content.</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span>To serve targeted advertisements through Google AdSense and other third-party ad networks.</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span>To comply with legal obligations and protect our legal rights.</li>
          </ul>
  
          <h2 style={styles.subtitle}>Third-Party Advertising</h2>
          <p style={styles.paragraph}>
            We partner with third-party vendors, including Google, to display advertisements on our site. These vendors may use cookies to serve ads based on your prior visits to our website or other websites. Google's use of advertising cookies enables it and its partners to serve ads to you based on your visit to our sites and other sites on the Internet. Users may opt out of personalized advertising by visiting Ads Settings. Alternatively, users can opt out of some third-party vendors' uses of cookies for personalized advertising by visiting [www.aboutads.info](https://www.aboutads.info/).
          </p>
  
          <h2 style={styles.subtitle}>Third-Party Vendors and Ad Networks</h2>
          <p style={styles.paragraph}>
            In addition to Google, other third-party vendors and ad networks may serve ads on our site. These include:
          </p>
          <ul style={styles.list}>
            <li style={styles.listItem}><span style={styles.bullet}>•</span>Outbrain</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span>Xandr (formerly AppNexus)</li>
            <li style={styles.listItem}><span style={styles.bullet}>•</span>Microsoft Advertising</li>
          </ul>
          <p style={styles.paragraph}>
            These third parties may use cookies to collect information about your interactions with our site and other sites to provide personalized ads. Users may visit the respective ad network websites to opt out of their use of cookies for personalized advertising.
          </p>
  
          <h2 style={styles.subtitle}>User Rights</h2>
          <p style={styles.paragraph}>
            You have the right to access, correct, or delete your personal data that we hold. You can exercise these rights by contacting us at the email address provided below. Additionally, you can manage your cookie preferences through your browser settings.
          </p>
  
          <h2 style={styles.subtitle}>Cookies</h2>
          <p style={styles.paragraph}>
            Our site uses cookies to enhance user experience, analyze site usage, and serve targeted advertisements. You can control cookie preferences through your browser settings. However, disabling cookies may affect certain functionalities of the site.
          </p>
  
          <h2 style={styles.subtitle}>Children's Privacy</h2>
          <p style={styles.paragraph}>
            We do not knowingly collect information from children under the age of 13. If we become aware that we have inadvertently collected personal data from a child under 13, we will take steps to delete such information.
          </p>
  
          <h2 style={styles.subtitle}>Data Security</h2>
          <p style={styles.paragraph}>
            We implement appropriate technical and organizational measures to protect your personal data from unauthorized access, alteration, disclosure, or destruction. However, please be aware that no method of data transmission over the internet or electronic storage is 100% secure.
          </p>
  
          <h2 style={styles.subtitle}>International Data Transfers</h2>
          <p style={styles.paragraph}>
            Your information, including personal data, may be transferred to and maintained on computers located outside your state, province, or country where data protection laws may differ. By using our site, you consent to such transfers.
          </p>
  
          <h2 style={styles.subtitle}>Changes to This Privacy Policy</h2>
          <p style={styles.paragraph}>
            We reserve the right to update this Privacy Policy periodically. Any changes will be posted on this page with an updated revision date. We encourage users to review this policy regularly to stay informed about our data practices.
          </p>
  
          <h2 style={styles.subtitle}>Contact Us</h2>
          <p style={styles.paragraph}>
            If you have any questions regarding this policy or our data practices, please contact us at: <a href="mailto:contact@grindrobux.com" style={styles.link}>contact@grindrobux.com</a>
          </p>
        </div>
        <footer style={{
      backgroundColor: '#181818',
      color: '#b3b3b3',
      padding: '20px 0',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Arial, sans-serif',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
      }}>
        <a href="/privacy-policy" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
        >
          Privacy Policy
        </a>
        <span style={{
          color: '#b3b3b3',
          margin: '0 10px',
        }}>
          |
        </span>
        <a href="/terms-of-service" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
        >
          Terms of Service
        </a>
      </div>
    </footer>
      </div>
    );
  };

const styles = {
  container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-start',
    backgroundColor: '#121212', 
    minHeight: '100vh',
    paddingTop: '80px',  // Adjust padding to ensure content doesn't get hidden under the header
    padding: '20px',
    overflowY: 'auto', 
    marginTop: '100px'
  },
  policyContent: {
    width: '90%',
    maxWidth: '900px',
    backgroundColor: '#1c1c1c',
    padding: '30px',
    borderRadius: '12px',
    boxShadow: '0 10px 20px rgba(0, 0, 0, 0.5)', 
    marginBottom: '20px', 
  },
  title: {
    textAlign: 'center',
    marginBottom: '30px',
    fontSize: '2.5rem',
    color: '#f0f0f0',
  },
  subtitle: {
    marginTop: '25px',
    fontSize: '1.8rem',
    color: '#f0f0f0',
  },
  paragraph: {
    lineHeight: '1.8',
    marginBottom: '15px',
    color: '#e0e0e0',
  },
  list: {
    margin: '10px 0 20px 20px',
    paddingLeft: '20px',
    color: '#e0e0e0',
  },
  listItem: {
    marginBottom: '10px',
    color: '#e0e0e0',
  },
  bullet: {
    color: '#f0f0f0',
    fontSize: '20px',
    marginRight: '10px',
  },
  link: {
    color: '#1e90ff',
    textDecoration: 'none',
  },
};

export default PrivacyPage;
