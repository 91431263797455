import React from 'react';
import './protected.css';

const SiteDownPage = () => {
  return (
    <div className='bodyfr'>
      <div className="header" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '80px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#0a2644',
        borderBottom: '1px solid #000000',
        zIndex: 1000,
      }}>
        {/* First Frame */}
        <div className="header-frame" style={{
          backgroundColor: '#223f99',
          height: '40px',
          borderRadius: '10px',
          width: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          fontFamily: 'system-ui, sans-serif',
          fontSize: '13px',
        }}>
          Adjust/S2S Converter by W_Ducky
        </div>
        
        {/* Second Frame with Small Spacing */}
        <a href="/" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
          >
            Home
          </a>
          <a href="mailto:contact@grindrobux.com" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
          >
            Contact
          </a>
          
      </div>
      <div className="containerfr">
        <div className="textfr">Sorry, the website is currently experiencing issues. Please contact ducky for it to be resolved.</div>
      </div>
      <footer style={{
      backgroundColor: '#181818',
      color: '#b3b3b3',
      padding: '20px 0',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Arial, sans-serif',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
      }}>
        <a href="/privacy-policy" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
        >
          Privacy Policy
        </a>
        <span style={{
          color: '#b3b3b3',
          margin: '0 10px',
        }}>
          |
        </span>
        <a href="/terms-of-service" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
        >
          Terms of Service
        </a>
      </div>
    </footer>
    </div>
  );
};

export default SiteDownPage;
