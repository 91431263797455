import React, { useState, useEffect, useRef } from 'react';
import './App.css';


function UrlConverter() {
  const [url, setUrl] = useState('');
  const [tp88url, settp88url] = useState('');
  const [originalURL, setoriginalURL] = useState('');

  const [adid, setADID] = useState('');
  const [appsflyerurl, setAppsFlyerUrl] = useState('');
  const [nickname, setNickname] = useState('');
  const [convertedUrls, setConvertedUrls] = useState([]);
  const [s2stimerurls, sets2stimerurls] = useState([]);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setsuccessMessage] = useState('');
  const [activeConverter, setActiveConverter] = useState('Adjust');
  const [activeS2SDevice, setActiveS2SDevice] = useState('');
  const [recentConverts, setRecentConverts] = useState([])
  const [s2slinks, sets2slinks] = useState([])
  const [ioss2slinks, setioss2slinks] = useState([])
  const [aflinks, setaflinks] = useState([])
  const [gamesList, setGamesList] = useState([]);
  const [iosgamesList, setiosGamesList] = useState([]);

  const [AFgamesList, setAFGamesList] = useState([]);
  const [showSetTimer, setShowSetTimer] = useState(false)
  const [showS2STimersList, setShowS2STimersList] = useState(false)
  const [s2sAuthBypass, setAuthBypass] = useState(false)
  const [currentVersion, setCurrentVersion] = useState(null);
  const versionRef = useRef(null); // Store version persistently
  const [updatePopup, setUpdatePopup] = useState(false);

  

  
  
  
  const [timerValue, setTimerValue] = useState({
    days: 0,
    hours: 0,
    minutes: 0
  });
  const [urlsetfortimer, seturlsetfortimer] = useState('Adjust');


  const popupRef = useRef(null);
  const fetchVersion = async () => {
    try {
      const authorization = localStorage.getItem('token') || null;
  
      const response = await fetch(`https://api.bucksburst.com/version`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorization}`
        },
      });
  
      if (response.ok) {
        const data = await response.text(); // Read the response once
        return data.trim(); // Trim to avoid formatting issues
      } else {
      }
    } catch (error) {
    }
    return ''; // Return empty string in case of error
  };
  
  
  useEffect(() => {
    const checkForUpdate = async () => {
      const latestVersion = await fetchVersion();
  
      if (latestVersion) {
        if (versionRef.current !== null && versionRef.current.trim() !== latestVersion.trim()) {
          setUpdatePopup(true);
        }
  
        setCurrentVersion(latestVersion.trim()); // Update state
        versionRef.current = latestVersion.trim(); // Persist version across renders
      }
    };
  
    checkForUpdate(); // Fetch on page load
  
    const intervalId = setInterval(checkForUpdate, 60000);
  
    return () => clearInterval(intervalId);
  }, []);
  

  
  const checkUserAuthentication = async () => {
    const authorization = localStorage.getItem('token') || null
    const response = await fetch(`https://api.bucksburst.com/auth/adjust/session`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorization}`
        },
        credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();
      setRecentConverts(data.converts);
    }
  }
  const getS2SLinks = async () => {
    const authorization = localStorage.getItem('token') || null
    const response = await fetch(`https://api.bucksburst.com/auth/adjust/get_s2s`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorization}`
        },
        credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();
      sets2slinks(data.links);
      const games = data.links.map(game => game._id);
      setGamesList(games);
    }
  }
  const getiosS2SLinks = async () => {
    const authorization = localStorage.getItem('token') || null
    const response = await fetch(`https://api.bucksburst.com/auth/adjust/get_ioss2s`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorization}`
        },
        credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();
      setioss2slinks(data.links);
      const games = data.links.map(game => game._id);
      setiosGamesList(games);
    }
  }
  
  const getAFLinks = async () => {
    const authorization = localStorage.getItem('token') || null
    const response = await fetch(`https://api.bucksburst.com/auth/adjust/get_af`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorization}`
        },
        credentials: 'include',
    });

    if (response.ok) {
      const data = await response.json();
      setaflinks(data.links);
      const games = data.links.map(game => game._id);
      setAFGamesList(games);
    }
  }
  
  useEffect(() => {
    checkUserAuthentication();
    getS2SLinks();
    getAFLinks();
    getiosS2SLinks()
    const urlParams = new URLSearchParams(window.location.search);
    const convertId = urlParams.get('id');
    if (convertId) {
      fetchConvertedUrls(convertId);
    }

    function handleClickOutside(event) {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setShowSetTimer(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchConvertedUrls = async (convertId) => {
    try {
      const authorization = localStorage.getItem('token') || null
      const response = await fetch(`https://api.bucksburst.com/fetchconvert`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorization}`
        },
        body: JSON.stringify({ "id": convertId }),
      });

      if (response.ok) {
        const data = await response.json();
        setConvertedUrls(data.urls);
        setUrl(data.url);
        setErrorMessage('');
        setsuccessMessage('')
        setNickname(data.nickname);
      } else {
        setErrorMessage('Failed to fetch convert with id ' + convertId);
        setsuccessMessage('')
      }
    } catch (error) {
      setErrorMessage('Failed to fetch convert with id ' + convertId);
      setsuccessMessage('')
    }
  };

  function validateGoogleAdID(adID) {
      if (String(adID).toLowerCase() === "zeesh") {
        return true
      }
      const pattern = /^[0-9a-fx]{8}-[0-9a-fx]{4}-[0-9a-fx]{4}-[0-9a-fx]{4}-[0-9a-fx]{12}$/i;
      return pattern.test(adID);
  }

  const [selectedGame, setSelectedGame] = useState("");
  const [gameWarning, setGameWarning] = useState(false);

  const handleSelect = (e) => {
    setSelectedGame(e.target.value);
  };
  function convAFurl(url, token) {
    const queryStringIndex = url.indexOf('?');
    if (queryStringIndex === -1) {
        return url;
    }

    const baseUrl = url.substring(0, queryStringIndex);
    const queryString = url.substring(queryStringIndex + 1);
    const params = new URLSearchParams(queryString);
    params.set('sub1', token);
    return `${baseUrl}?${params.toString()}`;
}
  const convertAFUrl = async () => {
    const appsFlyerUrl = "app.appsflyer.com";
    if (appsflyerurl.includes(appsFlyerUrl)) {
      const urlSearchParams = new URLSearchParams(appsflyerurl.split('?')[1]);
      if (!urlSearchParams.has('sub1')) {
          setErrorMessage("This AppsFlyer url is not Prodege");
          setsuccessMessage('');
          return;
      }
      const pid = urlSearchParams.get('pid');
      const afSiteId = urlSearchParams.get('af_siteid');
      if (pid !== 'swagbucks_int' || afSiteId !== 'Prodege') {
          setErrorMessage("This AppsFlyer url is not Prodege");
          setsuccessMessage('');
          return;
      }
      if (selectedGame !== "") {
        const selectedGameObj = aflinks.find(game => game._id === selectedGame);
        if (appsflyerurl.includes(selectedGameObj?.android_id)) {
          const gameUrls = selectedGameObj?.links || [];
          const convertedUrls = gameUrls.map(url => ({ url: `${convAFurl(appsflyerurl, url.token)}`, task: url.task, timer: url.timer }));
          setConvertedUrls(convertedUrls);
          setErrorMessage('');
          setsuccessMessage('');
        } else if (appsflyerurl.includes(selectedGameObj?.ios_id)) {
          setErrorMessage("IOS Coming soon");
          setsuccessMessage('')
        } else {
          setErrorMessage("Game does not match AppsFlyer link");
          setsuccessMessage('')
        }
      } else {
        setErrorMessage("Please select a game");
        setsuccessMessage('')
      }
    } else {
      setErrorMessage("Please enter a valid appsflyer url");
      setsuccessMessage('')
    }
  };
  const convertS2SUrl = async () => {
    if (validateGoogleAdID(adid)) {
      if (selectedGame !== "") {
        const selectedGameObj = s2slinks.find(game => game._id === selectedGame);
        const gameUrls = selectedGameObj?.links || [];
  
        if (s2sAuthBypass) {
          // Get the hostname and port
          const hostname = window.location.hostname;
          const port = window.location.port;
  
          // Determine the protocol based on hostname
          const protocol = hostname === 'localhost' ? 'http' : 'https';
  
          // Generate custom URLs with app_token, event_token, and adid
          const customUrls = gameUrls.map(url => {
            const urlParams = new URLSearchParams(new URL(url.link).search);
            const app_token = urlParams.get('app_token');
            const event_token = urlParams.get('event_token');
  
            // Construct the custom URL with app_token, event_token, and adid
            const customUrl = `${protocol}://${hostname}${port ? `:${port}` : ''}/s2s/auth?app_token=${app_token}&event_token=${event_token}&gps_adid=${adid}&device=${activeS2SDevice}&task=${url.task}`;
  
            // Return the custom URL along with the task and timer
            return { url: customUrl, task: url.task, timer: url.timer };
          });
  
          // Set the custom URLs to state
          setConvertedUrls(customUrls);
          setErrorMessage('');
          setsuccessMessage('');
        } else {
          // Update the URLs with the adid appended at the end if s2sAuthBypass is false
          const updatedUrls = gameUrls.map(url => {
            const updatedUrl = `${url.link}${adid}`; // Append adid to the original link
            return { url: updatedUrl, task: url.task, timer: url.timer };
          });
  
          setConvertedUrls(updatedUrls);
          setErrorMessage('');
          setsuccessMessage('');
          const hasTimerWarning = selectedGameObj?.timer_warning || false;
          setGameWarning(hasTimerWarning);
        }
      } else {
        setErrorMessage("Please select a game");
        setsuccessMessage('');
      }
    } else {
      setErrorMessage("Please enter a valid ADID");
      setsuccessMessage('');
    }
  };
  

  const convertiosS2SUrl = async () => {
    if (validateGoogleAdID(adid)) {
        if (selectedGame !== "") {
            const selectedGameObj = ioss2slinks.find(game => game._id === selectedGame);
            const gameUrls = selectedGameObj?.links || [];

            if (s2sAuthBypass) {
                // Get the hostname and port
                const hostname = window.location.hostname;
                const port = window.location.port;

                // Determine the protocol based on hostname
                const protocol = hostname === 'localhost' ? 'http' : 'https';

                // Generate custom URLs with app_token, event_token, and adid
                const customUrls = gameUrls.map(url => {
                    const urlParams = new URLSearchParams(new URL(url.link).search);
                    const app_token = urlParams.get('app_token');
                    const event_token = urlParams.get('event_token');

                    // Construct the custom URL
                    const customUrl = `${protocol}://${hostname}${port ? `:${port}` : ''}/s2s/auth?app_token=${app_token}&event_token=${event_token}&gps_adid=${adid}&device=${activeS2SDevice}&task=${url.task}`;

                    return { url: customUrl, task: url.task, timer: url.timer };
                });

                setConvertedUrls(customUrls);
                setErrorMessage('');
                setsuccessMessage('');
            } else {
                // Append adid to the original links if s2sAuthBypass is false
                const updatedUrls = gameUrls.map(url => ({
                    url: `${url.link}${adid}`,
                    task: url.task,
                    timer: url.timer
                }));

                setConvertedUrls(updatedUrls);
                setErrorMessage('');
                setsuccessMessage('');
                const hasTimerWarning = selectedGameObj?.timer_warning || false;
                setGameWarning(hasTimerWarning);
            }
        } else {
            setErrorMessage("Please select a game");
            setsuccessMessage('');
        }
    } else {
        setErrorMessage("Please enter a valid ADID");
        setsuccessMessage('');
    }
};

  
  const converttp88k = async () => {
    const urlParams = new URLSearchParams(tp88url.split('?')[1]);
    const uid = urlParams.get('uid');
    const sourceId = urlParams.get('source_id');

    // Extract all values from originalURL
    const originalUrlParams = new URLSearchParams(originalURL.split('?')[1]);
    const allValues = Array.from(originalUrlParams.values()).join(' ');

    // Regular expression to match the desired format (32-character alphanumeric strings)
    const regex = /\b[a-f0-9]{32}\b/g;
    const transactionIds = allValues.match(regex) || [];

    // Use a Set to ensure unique transaction IDs
    const uniqueTransactionIds = [...new Set(transactionIds)];

    // Manually extract the hostname from originalURL
    const siteName = originalURL.split('/')[2]; // This gets the hostname part

    // Fetch each URL and check the response
    const validTransactionIds = [];

    for (const transactionId of uniqueTransactionIds) {
        const generatedUrl = `https://www.tp88trk.com/sdk/click/?uid=${uid}&source_id=${sourceId}&_ef_transaction_id=${transactionId}&async=json`;

        // Prepare the request body
        const requestBody = {
            site_name: siteName,
            url: generatedUrl
        };

        try {
            const authorization = localStorage.getItem('token')
            const response = await fetch('https://api.bucksburst.com/tp88trk_request', {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  'Authorization': `Bearer ${authorization}`
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }

            const data = await response.json();

            // Check if the response indicates a valid transaction ID
            if (data['success'] === true) {
                validTransactionIds.push(transactionId);
            } else {
                console.log(`Invalid transaction ID for URL: ${generatedUrl}`);
            }
        } catch (error) {
            console.error(`Error fetching URL ${generatedUrl}:`, error.message);
        }
    }

    // Log the valid transaction IDs
    console.log("Valid Transaction IDs:", validTransactionIds);

    // Success message with the number of valid transaction IDs
    setsuccessMessage(`Compiled ${validTransactionIds.length} valid links.`);
    console.log(successMessage);

    return validTransactionIds;
};


  const convertUrl = async () => {
    if (!url.trim()) {
      setErrorMessage('Please enter a URL to convert.');
      setsuccessMessage('')
      setConvertedUrls([]);
      return;
    }

    try {
      const urlObj = new URL(url);
      const baseUrl = `${urlObj.protocol}//${urlObj.host}${urlObj.pathname}`;
      const urlParams = new URLSearchParams(urlObj.search);
      const callbacks = [];

      for (const [key, value] of urlParams) {
        if (key === 'install_callback' || key.startsWith('event_callback')) {
          const newUrl = `${baseUrl}?click_callback=${encodeURIComponent(value)}`;
          callbacks.push({
            url: newUrl,
            timer: null,
            clicked: false
          });
        }
      }

      if (callbacks.length === 0) {
        setErrorMessage('No valid install or event callbacks found in the URL.');
        setsuccessMessage('')
        setConvertedUrls([]);
        return;
      }

      setConvertedUrls(callbacks);
      setErrorMessage('');
      setsuccessMessage('')

      const authorization = localStorage.getItem('token')
      if (authorization) {
        const response = await fetch('https://api.bucksburst.com/create_convert', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorization}`
          },
          body: JSON.stringify({
            url: url,
            urls: callbacks,
            nickname: nickname
          }),
        });

        if (response.ok) {
          const data = await response.json();
          const convertId = data.id;
          const newUrl = `${window.location.origin}/?id=${convertId}`;
          window.history.pushState({ path: newUrl }, '', newUrl);
          checkUserAuthentication()
        } else {
          console.error('Failed to make POST request');
        }
      }

    } catch (error) {
      setsuccessMessage('')
      setErrorMessage('Invalid URL. Please enter a valid URL to convert.');
      setConvertedUrls([]);
    }
  };


  const copyAllToClipboard = () => {
    const allUrls = convertedUrls.map(item => item.url).join('\n');
    navigator.clipboard.writeText(allUrls).then(() => {}, (err) => {
      console.error('Failed to copy: ', err);
    });
  };
  const openNewTab = async (newUrl) => {
    window.open(newUrl, '_blank');
  };

  const openInNewTab = async (newUrl) => {
    const authorization = localStorage.getItem('token')
    const response = await fetch('https://api.bucksburst.com/clicked_url', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorization}`
      },
      body: JSON.stringify({ url: newUrl.url })
    });
    if (response.ok) {
      const urlParams = new URLSearchParams(window.location.search);
      const convertId = urlParams.get('id');
      if (convertId) {
        fetchConvertedUrls(convertId);
      }
    }
    window.open(newUrl.url, '_blank');
  };
  const clearEverything = (converter) => {
    if (converter === "Adjust") {
      setConvertedUrls([]);
      setErrorMessage('');
      setsuccessMessage('')
      setUrl('');
      setNickname('');
      setGameWarning(false);
      window.location.href = `/`;
    } else {
      setConvertedUrls([]);
      setErrorMessage('');
      setsuccessMessage('')
      setADID('')
      setSelectedGame('')
      setGameWarning(false);
      setAppsFlyerUrl('')
    }
  }

  const handleConverterSwitch = (converter) => {
    window.history.pushState({ path: '/' }, '', '/');
  
    setActiveConverter(converter);
    setConvertedUrls([]);
    setErrorMessage('');
    setsuccessMessage('');
    setUrl('');
    setNickname('');
    setSelectedGame('');
    setADID('');
    setGameWarning(false);
    setAppsFlyerUrl('');
    setActiveS2SDevice('');
  
    if (converter === "Adjust") {
      const urlParams = new URLSearchParams(window.location.search);
      const convertId = urlParams.get('id');
      if (convertId) {
        fetchConvertedUrls(convertId);
      }
    }
  };

  const handleRecentConvertClick = (convertId) => {
    window.location.href = `/?id=${convertId}`;
  };

  const deleteConvert = async (convertId) => {
    const urlParams = new URLSearchParams(window.location.search);
    const convertId2 = urlParams.get('id');
    const authorization = localStorage.getItem('token')
    const response = await fetch('https://api.bucksburst.com/delete_convert', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${authorization}`
      },
      body: JSON.stringify({ id: convertId })
    });
    if (response.ok) {
      checkUserAuthentication()
      if (String(convertId) === String(convertId2)) {
        window.location.href = `/`;
      }
    } else {
      setErrorMessage("Failed to delete.")
    }
  };


  const deleteTimer = async (newUrl) => {
    const authorization = localStorage.getItem('token')
    const response = await fetch('https://api.bucksburst.com/delete_url_timer', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorization}`
        },
        body: JSON.stringify({ url: newUrl.url, type: activeConverter, timestamp: newUrl.timestamp || newUrl.timer})
    });
    if (response.ok) {
        setShowSetTimer(false);
        if (activeConverter === "Adjust") {
          const urlParams = new URLSearchParams(window.location.search);
          const convertId = urlParams.get('id');
          if (convertId) {
              fetchConvertedUrls(convertId);
          }
        } else {
          setShowSetTimer(false);
          await getS2SGameTimers(selectedGame)
        }
    }
};


  
  
const handleOpenAll = () => {
  const authorization = localStorage.getItem('token');
  setErrorMessage('');
  setsuccessMessage('');

  if (s2sAuthBypass) {
    if (convertedUrls && convertedUrls.length > 0) {
      const firstLinkObj = convertedUrls[0];
      const firstUrl = new URL(firstLinkObj.url);
      const firstParams = new URLSearchParams(firstUrl.search);
      const firstEventToken = firstParams.get('event_token');
      const adidParam = firstParams.get('gps_adid');  // Extract adid from the first URL
      const appToken = firstParams.get('app_token'); // Extract app_token from the first URL
      
      if (!firstEventToken || !adidParam || !appToken) {
        console.error('Event token, adid, or app_token is missing!');
        return;
      }

      // Ensures first event token includes the task and adid/app_token
      let combinedUrl = `${firstUrl.origin}${firstUrl.pathname}?multiple=true&device=${activeS2SDevice}`;
      combinedUrl += `&event_token=${firstEventToken} | ${firstLinkObj.task}`;
      combinedUrl += `&gps_adid=${adidParam}&app_token=${appToken}`; // Add adid and app_token from first URL

      console.log('First Event Token:', firstEventToken);
      console.log('First Task:', firstLinkObj.task);

      // Append the remaining URLs with adid and app_token extracted from each URL
      convertedUrls.slice(1).forEach(linkObj => {
        const urlObj = new URL(linkObj.url);
        const params = new URLSearchParams(urlObj.search);
        const eventToken = params.get('event_token');

        if (eventToken) {
          console.log('Event Token:', eventToken, 'Task:', linkObj.task);
          combinedUrl += `&event_token=${eventToken} | ${linkObj.task}`;
        } else {
          console.log('Missing event_token, adid, or app_token for this link');
        }
      });

      console.log('Final Combined URL:', combinedUrl);
      openNewTab(combinedUrl);
    } else {
      setErrorMessage("No links available.");
    }
  } else {
    const links = s2slinks.find(game => game._id === selectedGame)?.links || [];

    if (links && links.length > 0) {
      links.forEach(async (link) => {
        try {
          const response = await fetch('https://api.bucksburst.com/open_s2s_link', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authorization}`,
            },
            body: JSON.stringify({ link: link.link + adid }),
          });
          if (!response.ok) {
            const data = await response.json();
            setErrorMessage(data.error);
          } else {
            setsuccessMessage("Successfully opened links.");
          }
        } catch (error) {
          setErrorMessage(`Failed to open all: ${error}`);
        }
      });
    } else {
      setErrorMessage("No links available.");
    }
  }
};






  const getS2SGameTimers = async (game) => {
    try {
      const authorization = localStorage.getItem('token')
      const response = await fetch('https://api.bucksburst.com/get_s2s_timers', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authorization}`
        },
        body: JSON.stringify({ game: game })
      });
      if (!response.ok) {
        const data = await response.json();
        setErrorMessage(data.error);
      } else {
        const data = await response.json();
        sets2stimerurls(data.timers)
      }
    } catch (error) {
      setErrorMessage(`Failed to get s2s timers`);
    }
  }

  const handlesetshowtimer = async (option) => {
    if (option) {
      await getS2SGameTimers(selectedGame)
    }
    setShowS2STimersList(option)
  }

  const handleSetTimer = async (newUrl) => {
      // Convert days, hours, and minutes to seconds
      const seconds =
          timerValue.days * 24 * 60 * 60 +
          timerValue.hours * 60 * 60 +
          timerValue.minutes * 60;

      const unixTimestamp = Math.floor(Date.now() / 1000) + seconds;
      var bodyjson
      if (activeConverter === "Adjust") {
        bodyjson = JSON.stringify({ url: newUrl.url, timestamp: unixTimestamp, game: null, type: "adjust"})
      } else {
        bodyjson = JSON.stringify({ url: newUrl.url, timestamp: unixTimestamp, game: selectedGame, type: "s2s"})
      }
      const authorization = localStorage.getItem('token')
      const response = await fetch('https://api.bucksburst.com/set_url_timer', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${authorization}`
          },
          body: bodyjson
      });
      if (response.ok) {
        if (activeConverter === "Adjust") {
          setShowSetTimer(false);
          const urlParams = new URLSearchParams(window.location.search);
          const convertId = urlParams.get('id');
          if (convertId) {
              fetchConvertedUrls(convertId);
          }
        } else {
          setShowSetTimer(false);
          handlesetshowtimer(true)
        }
      }
  };
  const [timers, setTimers] = useState([]);
  useEffect(() => {
    const intervalId = setInterval(() => {
        const updatedTimers = convertedUrls.map((newUrl, index) => {
            if (newUrl.timer && newUrl.timer > Math.floor(Date.now() / 1000)) {
                return newUrl.timer - Math.floor(Date.now() / 1000);
            } else if (newUrl.timer && newUrl.timer <= Math.floor(Date.now() / 1000)) {
                // Timer has hit 0, update the clicked property
                const updatedUrls = [...convertedUrls];
                updatedUrls[index] = {...newUrl, clicked: true};
                setConvertedUrls(updatedUrls);
                return null;
            } else {
                return null;
            }
        });
        setTimers(updatedTimers);
    }, 1000);
    

    return () => clearInterval(intervalId);
}, [convertedUrls]);
const [s2stimers, sets2sTimers] = useState([]);
  useEffect(() => {
    const intervalId = setInterval(() => {
        const updatedTimers = s2stimerurls.map((newUrl, index) => {
            if (newUrl.timestamp && newUrl.timestamp > Math.floor(Date.now() / 1000)) {
                return newUrl.timestamp - Math.floor(Date.now() / 1000);
            } else if (newUrl.timestamp && newUrl.timestamp <= Math.floor(Date.now() / 1000)) {
              handlesetshowtimer(true)
            } else {
                return null;
            }
        });
        sets2sTimers(updatedTimers);
    }, 1000);
    return () => clearInterval(intervalId);
}, [s2stimerurls]);


  const formatTime = (time) => {
    const seconds = time % 60;
    const minutes = Math.floor((time / 60) % 60);
    const hours = Math.floor((time / (60 * 60)) % 24);
    const days = Math.floor(time / (24 * 60 * 60));
    return `${days}d:${hours}h:${minutes}m:${seconds}s`;
  };

  const [sidebarVisible, setSidebarVisible] = useState(false);

  const handleToggleSidebar = () => {
    setSidebarVisible(!sidebarVisible);
  };
  const handleDeviceClick = (device) => {
    setActiveS2SDevice(device);
    setConvertedUrls([]);
    setErrorMessage('');
    setsuccessMessage('');
    setSelectedGame('');
    setADID('');
    setGameWarning(false);
  };


  const recentConvertsList = [...recentConverts].reverse().map((convert, index) => (
    <div key={index} className="recent-convert" onClick={() => handleRecentConvertClick(convert.id)}>
        <div className="recent-convert-text">
            <span>{convert.nickname || convert.id}</span>
        </div>
        <div className="close-button-container" onClick={(e) => {
            e.stopPropagation(); // Stop event propagation
            deleteConvert(convert.id);
        }}>
            X
        </div>
    </div>
));


  return (
    <div>
      {showSetTimer && (
        <div className="popup-overlay" onClick={() => setShowSetTimer(false)}>
          <div className="popup-content" ref={popupRef} onClick={e => e.stopPropagation()}>
            <button onClick={() => setShowSetTimer(false)} className="close-btn">×</button>
            <div className="input-group">
              <span>Minutes</span>
              <input type="number" placeholder="Minutes" id="minutes" value={timerValue.minutes} onChange={(e) => setTimerValue({ ...timerValue, minutes: parseInt(e.target.value) })}/>
              <span>Hours</span>
              <input type="number" placeholder="Hours" id="hours" value={timerValue.hours} onChange={(e) => setTimerValue({ ...timerValue, hours: parseInt(e.target.value) })}/>
              <span>Days</span>
              <input type="number" placeholder="Days" id="days" value={timerValue.days} onChange={(e) => setTimerValue({ ...timerValue, days: parseInt(e.target.value) })}/>
            </div>
            <button onClick={() => handleSetTimer(urlsetfortimer)} className="set-btn">Set</button>
          </div>
        </div>
      )}

      {updatePopup && (
        <div className="popup-overlay1" onClick={() => setUpdatePopup(false)}>
          <div className="popup-content1" ref={popupRef} onClick={e => e.stopPropagation()}>
            <p className="update-text1">
              A new site update has been released. Please reload the page for the best performance and bug fixes.
            </p>
            <div className="close-btn-container1">
              <button onClick={() => setUpdatePopup(false)}>Close</button>
            </div>
          </div>
        </div>
      )}
      {showS2STimersList && (
        <div className="popup-overlay" onClick={() => handlesetshowtimer(false)}>
          <div className="popup-content" style={{ width: "70%", height: "60%" }} ref={popupRef} onClick={e => e.stopPropagation()}>
            <button onClick={() => handlesetshowtimer(false)} className="close-btn">×</button>
            <div style={{alignSelf: "center", fontSize: "19px", fontWeight: "bold"}}>{selectedGame} Timers</div>
            <div className="scrolling-frame" style={{ height: "97%",width: "98%", overflow: "auto" }}>
            {s2stimerurls.map((newUrl, index) => (
              <div key={index} style={{cursor: "default"}} className="url-frame">
                <div className="url-text">{newUrl.url}</div>
                <div style={{ display: "flex", alignItems: "center" }}>
                    {s2stimers[index] !== null && (
                      <div style={{ backgroundColor: "gray", borderRadius: "10px", width: "100px", height: "30px", display: "flex", alignItems: "center", fontSize: "11px", justifyContent: "center", fontFamily: "system-ui, sans-serif", fontWeight: "bold", marginRight: "10px" }}>
                        {formatTime(s2stimers[index])}
                      </div>
                    )}
                    {s2stimers[index] !== null && (
                      <div className="close-button-container" onClick={(e) => {
                        e.stopPropagation();
                        deleteTimer(newUrl);
                      }}>
                        X
                      </div>
                    )}
                  </div>
              </div>
            ))}
            </div>
          </div>
        </div>
      )}



        <div className="header" style={{
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          height: '80px',
          padding: '10px',
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          backgroundColor: '#0a2644',
          borderBottom: '1px solid #000000',
          zIndex: 1000,
        }}>
          {/* First Frame */}
          <div className="header-frame" style={{
            backgroundColor: '#223f99',
            height: '40px',
            borderRadius: '10px',
            width: '150px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontWeight: 'bold',
            fontFamily: 'system-ui, sans-serif',
            fontSize: '13px',
          }}>
            Adjust/S2S Converter by W_Ducky
          </div>
          
          {/* Second Frame with Small Spacing */}
          <a href="/" style={{
              color: '#b3b3b3',
              textDecoration: 'none',
              margin: '0 10px',
              transition: 'color 0.3s ease',
            }} 
            onMouseEnter={(e) => e.target.style.color = '#ffffff'}
            onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
            >
              Home
            </a>
            <a href="mailto:contact@grindrobux.com" style={{
              color: '#b3b3b3',
              textDecoration: 'none',
              margin: '0 10px',
              transition: 'color 0.3s ease',
            }} 
            onMouseEnter={(e) => e.target.style.color = '#ffffff'}
            onMouseLeave={(e) => e.target.style.color = '#b3b3b3' }
            >
              Contact
            </a>
            
        </div>
      <div className="container" style={{
    marginTop: '100px', // Added margin-top to ensure content isn't hidden behind the header
  }}>
      <div className="converter-container">
        <select
          className="converter-dropdown"
          value={activeConverter}
          onChange={(e) => handleConverterSwitch(e.target.value)}
        >
          <option value="Adjust">Normal Adjust</option>
          <option value="S2S">S2S Adjust</option>
          <option value="AppsFlyer">AppsFlyer</option>
          <option value="tp88trk">Tp88trk</option>
        </select>
      </div>
      <div className={`recent-converts-frame ${sidebarVisible ? '' : 'hidden'}`} style={{
          position: 'fixed',
          top: '10px', // Adjust if needed
          right: '10px', // This will place it on the top-right
          zIndex: 1000, // Ensure it appears above other content
        }}>
          <button onClick={handleToggleSidebar} className="open-button">
            {sidebarVisible ? '>>>' : '<<<'}
          </button>
          <div className="recent-converts-title">Recent Converts</div>
          <div className="recent-converts-scroll">
            {recentConvertsList}
          </div>
        </div>
        {activeConverter === 'Adjust' && (
          <>
            <input type="text" value={url} onChange={(e) => setUrl(e.target.value)} placeholder="Enter Adjust Link" />
            <input style={{width: "200px"}} type="text" value={nickname} onChange={(e) => setNickname(e.target.value)} placeholder="Enter Convert Nickname" />
            <button style={{backgroundColor: "green", fontFamily: "system-ui, sans-serif", fontWeight: "bold"}} onClick={convertUrl}>Convert</button>
            {convertedUrls.length > 0 && <button onClick={copyAllToClipboard} style={{ marginTop: '10px', fontFamily: "system-ui, sans-serif", fontWeight: "bold"}}>Copy All</button>}
            {convertedUrls.length > 0 && <button onClick={() => clearEverything('Adjust')} style={{ marginTop: '10px', fontFamily: "system-ui, sans-serif", fontWeight: "bold"}}>Clear All</button>}
          </>
        )}
        {activeConverter === 'tp88trk' && (
          <>
            <input type="text" value={tp88url} onChange={(e) => settp88url(e.target.value)} placeholder="Enter tp88trk Link" />
            <input type="text" value={originalURL} onChange={(e) => setoriginalURL(e.target.value)} placeholder="Enter website Link" />
            <button style={{backgroundColor: "green", fontFamily: "system-ui, sans-serif", fontWeight: "bold"}} onClick={converttp88k}>Convert</button>
          </>
        )}
        {activeConverter === 'S2S' && (
          <>
            <div className="converter-buttons">
              <button
                className={activeS2SDevice === 'Android' ? 'active' : ''}
                onClick={() => handleDeviceClick('Android')}
              >
                Android
              </button>
              <button
                className={activeS2SDevice === 'IOS' ? 'active' : ''}
                onClick={() => handleDeviceClick('IOS')}
              >
                IOS
              </button>
            </div>

            {activeS2SDevice && (
              <>
                 {/* Auth Bypass Checkbox */}
                 <div>
                    <label style={{ display: 'flex', alignItems: 'center', fontSize: '16px' }}>
                      <input
                        type="checkbox"
                        checked={s2sAuthBypass}
                        onChange={() => setAuthBypass(!s2sAuthBypass)}
                        style={{
                          width: '20px',
                          height: '20px',
                          marginRight: '8px',
                          accentColor: '#4CAF50',  // Green color for the checkbox
                        }}
                      />
                      <span>Auth Bypass</span>
                    </label>
                  </div>
                {activeS2SDevice === 'Android' && (
                  <>
                    <input
                      style={{ width: '400px' }}
                      type="text"
                      value={adid}
                      onChange={(e) => setADID(e.target.value)}
                      placeholder="Enter Device ADID"
                    />
                    <a href="https://play.google.com/store/apps/details?id=com.appsflyer.android.deviceid&hl=en_US&gl=US">
                      Click for ADID: My Device ID by AppsFlyer
                    </a>
                    <div className="dropdown-container">
                      <label htmlFor="games" className="dropdown-label">Select a game:</label>
                      <select
                        id="games"
                        value={selectedGame}
                        onChange={handleSelect}
                        className="dropdown-select"
                      >
                        <option value="">Select a game</option>
                        {gamesList.map((game, index) => (
                          <option key={index} value={game}>{game}</option>
                        ))}
                      </select>
                    </div>
                    <button
                      style={{
                        backgroundColor: 'green',
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 'bold',
                      }}
                      onClick={convertS2SUrl}
                    >
                      Convert
                    </button>
                    {convertedUrls.length > 0 && (
                      <>
                        {!s2sAuthBypass && (  // Only show Copy All when s2sAuthBypass is false
                          <button
                            onClick={copyAllToClipboard}
                            style={{
                              marginTop: '10px',
                              backgroundColor: 'gray',
                              fontFamily: 'system-ui, sans-serif',
                              fontWeight: 'bold',
                            }}
                          >
                            Copy All
                          </button>
                        )}
                        <button
                          onClick={() => clearEverything('S2S')}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'red',
                            fontFamily: 'system-ui, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          Clear All
                        </button>
                        <button
                          onClick={handleOpenAll}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'orange',
                            fontFamily: 'system-ui, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          Open All
                        </button>
                        <button
                          onClick={() => handlesetshowtimer(true)}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'blue',
                            fontFamily: 'system-ui, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          Timers
                        </button>
                      </>
                    )}
                  </>
                )}
                {activeS2SDevice === 'IOS' && (
                  <>
                    <input
                      style={{ width: '400px' }}
                      type="text"
                      value={adid}
                      onChange={(e) => setADID(e.target.value)}
                      placeholder="Enter Device IDFA"
                    />
                    <a href="https://apps.apple.com/us/app/my-device-id-by-appsflyer/id1192323960">
                      Click for IDFA: My Device ID by AppsFlyer
                    </a>
                    <div className="dropdown-container">
                      <label htmlFor="games" className="dropdown-label">Select a game:</label>
                      <select
                        id="games"
                        value={selectedGame}
                        onChange={handleSelect}
                        className="dropdown-select"
                      >
                        <option value="">Select a game</option>
                        {iosgamesList.map((game, index) => (
                          <option key={index} value={game}>{game}</option>
                        ))}
                      </select>
                    </div>
                    <button
                      style={{
                        backgroundColor: 'green',
                        fontFamily: 'system-ui, sans-serif',
                        fontWeight: 'bold',
                      }}
                      onClick={convertiosS2SUrl}
                    >
                      Convert
                    </button>
                    {convertedUrls.length > 0 && (
                      <>
                        <button
                          onClick={copyAllToClipboard}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'gray',
                            fontFamily: 'system-ui, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          Copy All
                        </button>
                        <button
                          onClick={() => clearEverything('S2S')}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'red',
                            fontFamily: 'system-ui, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          Clear All
                        </button>
                        <button
                          onClick={handleOpenAll}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'orange',
                            fontFamily: 'system-ui, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          Open All
                        </button>
                        <button
                          onClick={() => handlesetshowtimer(true)}
                          style={{
                            marginTop: '10px',
                            backgroundColor: 'blue',
                            fontFamily: 'system-ui, sans-serif',
                            fontWeight: 'bold',
                          }}
                        >
                          Timers
                        </button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </>
        )}


        {activeConverter === 'AppsFlyer' && (
          <>
            <input style={{width: "400px"}} type="text" value={appsflyerurl} onChange={(e) => setAppsFlyerUrl(e.target.value)} placeholder="Enter AppsFlyer URL: " />
            <div className="dropdown-container">
              <label htmlFor="games" className="dropdown-label">Select a game:</label>
              <select id="games" value={selectedGame} onChange={handleSelect} className="dropdown-select">
                <option value="">Select a game</option>
                {AFgamesList.map((game, index) => (
                  <option key={index} value={game}>{game}</option>
                ))}
              </select>
            </div>
            <button style={{backgroundColor: "green", fontFamily: "system-ui, sans-serif", fontWeight: "bold"}} onClick={convertAFUrl}>Convert</button>
            {convertedUrls.length > 0 && <button onClick={copyAllToClipboard} style={{ marginTop: '10px', backgroundColor: "gray",cfontFamily: "system-ui, sans-serif", fontWeight: "bold" }}>Copy All</button>}
            {convertedUrls.length > 0 && <button onClick={() => clearEverything('AppsFlyer')} style={{ marginTop: '10px', backgroundColor: "red", fontFamily: "system-ui, sans-serif", fontWeight: "bold" }}>Clear All</button>}
          </>
        )}
        
        {errorMessage && <div className="error-message">{errorMessage}</div>}
        {successMessage && <div className="success-message">{successMessage}</div>}
        {gameWarning && (
          <div style={{color: "red", marginTop: "10px", fontWeight: "bold"}}>Warning: This game has timers. Posting the links before the timers listed will void the events. Just do it according it to the timer dude. How timers work: If it says 40m, it means you have to wait 40mins after opening the game</div>
        )}
        <div className="iframe-container">
        {convertedUrls.map((newUrl, index) => (
          <div key={index} className="url-frame">
            {newUrl.task && (
            <div style={{
              backgroundColor: "gray",
              borderRadius: "10px",
              width: "100px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontFamily: "system-ui, sans-serif",
              fontWeight: "bold",
              marginRight: "5px",
              fontSize: newUrl.task.length > 12 ? "12px" : newUrl.task.length > 10 ? "small" : "inherit",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              {newUrl.task}
            </div>
          )}
            <div className="url-text" onClick={() => openInNewTab(newUrl)}>{newUrl.url}</div>
            {activeConverter === "S2S" && (
              <>
                {newUrl.timer && (
                  <div
                    style={{
                      backgroundColor: "gray",
                      borderRadius: "10px",
                      width: "100px",
                      height: "30px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      fontFamily: "system-ui, sans-serif",
                      fontWeight: "bold",
                      marginRight: "5px",
                      fontSize: newUrl.timer.length > 10 ? "smaller" : "inherit",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis"
                    }}
                  >
                    {newUrl.timer}
                  </div>
                )}
              </>
            )}

            {newUrl.clicked ? (
              <div style={{ backgroundColor: "green", borderRadius: "10px", width: "100px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "system-ui, sans-serif", fontWeight: "bold"}}>
                Clicked
              </div>
            ) : (
              <>
                { activeConverter === 'S2S' ? (
                <div 
                  style={{ 
                    backgroundColor: "orange", 
                    borderRadius: "10px", 
                    width: "100px", 
                    height: "30px", 
                    display: "flex", 
                    alignItems: "center", 
                    justifyContent: "center", 
                    fontWeight: "bold", 
                    fontSize: "15px"
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowSetTimer(true);
                    seturlsetfortimer(newUrl)
                    timerValue.minutes = 0;
                    timerValue.hours = 0;
                    timerValue.days = 0;
                  }}
                >
                  Set Timer
                </div>
              ) : null }
                {(newUrl.timer === null || newUrl.timer === undefined) && activeConverter !== 'S2S' ? (
                  <div 
                    style={{ backgroundColor: "blue", borderRadius: "10px", width: "100px", height: "30px", display: "flex", alignItems: "center", justifyContent: "center", fontFamily: "system-ui, sans-serif", fontWeight: "bold", fontSize: "13px"}}
                    onClick={(e) => {
                      e.stopPropagation();
                      setShowSetTimer(true);
                      seturlsetfortimer(newUrl)
                      timerValue.minutes = 0;
                      timerValue.hours = 0;
                      timerValue.days = 0;
                    }}
                  >
                    Set Timer
                  </div>
                ) : activeConverter !== 'S2S' && (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {timers[index] !== null && (
                      <div style={{ backgroundColor: "gray", borderRadius: "10px", width: "100px", height: "30px", display: "flex", alignItems: "center", fontSize: "11px", justifyContent: "center", fontFamily: "system-ui, sans-serif", fontWeight: "bold", marginRight: "10px" }}>
                        {formatTime(timers[index])}
                      </div>
                    )}
                    {timers[index] !== null && (
                      <div className="close-button-container" onClick={(e) => {
                        e.stopPropagation();
                        deleteTimer(newUrl);
                      }}>
                        X
                      </div>
                    )}
                  </div>

                )}
              </>
            )}
          </div>
        ))}
        </div>
      </div>
      <footer style={{
      backgroundColor: '#181818',
      color: '#b3b3b3',
      padding: '20px 0',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Arial, sans-serif',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
      }}>
        <a href="/privacy-policy" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
        >
          Privacy Policy
        </a>
        <span style={{
          color: '#b3b3b3',
          margin: '0 10px',
        }}>
          |
        </span>
        <a href="/terms-of-service" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
        >
          Terms of Service
        </a>
      </div>
    </footer>
      
    </div>
  );
}

export default UrlConverter;