import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SiteDownPage from './SiteDownPage';
import './protected.css';

const Protected = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      const authorization = localStorage.getItem('authorization');

      if (!token) {
        setLoading(false);
        return;
      }

      // Step 1: Try authentication with only token
      let authHeader = `Bearer ${token}`;

      try {
        let response = await fetch('https://api.bucksburst.com/auth/adjust', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader,
          },
        });

        let data = await response.json();

        // If authentication succeeds and user has transferred, remove authorization
        if (response.ok) {
          if (data?.has_transferred && authorization) {
            localStorage.removeItem('authorization');
          }
          setIsAuthenticated(true);
          return;
        }

        // Step 2: If not transferred, try with both token and authorization
        if (!response.ok && authorization) {
          authHeader = `Bearer ${token}|${authorization}`;

          response = await fetch('https://api.bucksburst.com/auth/adjust', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': authHeader,
            },
          });

          data = await response.json();

          // If transfer is completed after second check, remove authorization
          if (response.ok && data?.has_transferred) {
            localStorage.removeItem('authorization');
          }
        }

        if (response.ok) {
          setIsAuthenticated(true);
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setError(true);
      } finally {
        setLoading(false);
      }
    };

    validateToken();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <SiteDownPage />;
  if (isAuthenticated) {
    history('/');
    return null;
  }

  // Get the current hostname and port (if present)
  const currentHost = window.location.hostname;
  const currentPort = window.location.port ? `:${window.location.port}` : '';
  const protocol = currentHost === 'localhost' ? 'http' : 'https';

  const discordRedirectURI = `${protocol}://${currentHost}${currentPort}/auth/discord/callback`;

  return (
    <div className='bodyfr'>
      {/* Header at the Top */}
      <div className="header" style={{
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        height: '80px',
        padding: '10px',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        backgroundColor: '#0a2644',
        borderBottom: '1px solid #000000',
        zIndex: 1000,
      }}>
        {/* First Frame */}
        <div className="header-frame" style={{
          backgroundColor: '#223f99',
          height: '40px',
          borderRadius: '10px',
          width: '150px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          fontWeight: 'bold',
          fontFamily: 'system-ui, sans-serif',
          fontSize: '13px',
        }}>
          Adjust/S2S Converter by W_Ducky
        </div>
        
        {/* Second Frame with Small Spacing */}
        <a href="/" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
          >
            Home
          </a>
          <a href="/" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
          >
            Contact
          </a>
          
      </div>

      {/* Main Content */}
      <div className="containerfr" style={{ marginTop: '100px' }}>
        <div className="textfr">You are not authenticated, please contact Admin or login below!</div>
        <button
          className="login-button"
          onClick={() =>
            window.location.href = `https://discord.com/api/oauth2/authorize?client_id=1202517446058184756&response_type=code&redirect_uri=${encodeURIComponent(discordRedirectURI)}&scope=identify`
          }
        >
          Login to Discord
        </button>
      </div>

      {/* Footer */}
      <footer style={{
        backgroundColor: '#181818',
        color: '#b3b3b3',
        padding: '20px 0',
        position: 'fixed',
        bottom: 0,
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontFamily: 'Arial, sans-serif',
      }}>
        <div style={{
          display: 'flex',
          alignItems: 'center',
          fontSize: '14px',
        }}>
          <a href="/privacy-policy" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
          >
            Privacy Policy
          </a>
          <span style={{
            color: '#b3b3b3',
            margin: '0 10px',
          }}>
            |
          </span>
          <a href="/terms-of-service" style={{
            color: '#b3b3b3',
            textDecoration: 'none',
            margin: '0 10px',
            transition: 'color 0.3s ease',
          }} 
          onMouseEnter={(e) => e.target.style.color = '#ffffff'}
          onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
          >
            Terms of Service
          </a>
        </div>
      </footer>
    </div>
  );
};

export default Protected;
