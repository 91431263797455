import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import SiteDownPage from './SiteDownPage';
import UrlConverter from './UrlConverter';

const ProtectedRoute = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(false);
  const history = useNavigate();

  useEffect(() => {
    const validateToken = async () => {
      const token = localStorage.getItem('token');
      const authorization = localStorage.getItem('authorization');

      if (!token) {
        history('/login');
        return;
      }

      // Step 1: Try authentication with only token
      let authHeader = `Bearer ${token}`;

      try {
        let response = await fetch('https://api.bucksburst.com/auth/adjust', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': authHeader,
          },
        });

        let data = await response.json();

        // If authentication is successful and user has already transferred, remove authorization from localStorage
        if (response.ok) {
          if (data?.has_transferred && authorization) {
            localStorage.removeItem('authorization');
          }
          setIsAuthenticated(true);
          return;
        }

        // Step 2: If not transferred, try with both token and authorization
        if (!response.ok && authorization) {
          authHeader = `Bearer ${token}|${authorization}`;

          response = await fetch('https://api.bucksburst.com/auth/adjust', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': authHeader,
            },
          });

          data = await response.json();

          // If transfer is completed after second check, remove authorization from localStorage
          if (response.ok && data?.has_transferred) {
            localStorage.removeItem('authorization');
          }
        }

        if (response.ok) {
          setIsAuthenticated(true);
        } else {
          setIsAuthenticated(false);
          history('/login');
        }
      } catch (error) {
        console.error('Authentication error:', error);
        setIsAuthenticated(false);
        setError(true);
      }
    };

    validateToken();
  }, [history]);

  if (error) {
    return <SiteDownPage />;
  }

  return isAuthenticated ? <UrlConverter /> : null;
};

export default ProtectedRoute;
