import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import { solarizedDark } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import styles from './S2SAuthComponent.module.css'; // Import your correct CSS module

function S2SAuthComponent() {
  const location = useLocation();
  const [jsonResponse, setJsonResponse] = useState(null);

  const params = new URLSearchParams(location.search);
  const apptoken = params.get('app_token');
  const adid = params.get('gps_adid');
  const event_token = params.get('event_token');
  const device = params.get('device');

  const isMultipleRequests = params.has('multiple');

  // Build the request data
  const buildRequestData = () => {
    if (!isMultipleRequests) {
      const task = params.get('task');
      return {
        multiple: false,
        app_token: apptoken,
        adid: adid,
        event_token: event_token,
        task: task,
        device: device
      };
    }

    const requests = [];
    const tokens = params.getAll('event_token');
    tokens.forEach((token) => {
      requests.push({
        app_token: apptoken,
        adid: adid,
        event_token: token,
        device: device
      });
    });

    return {
      multiple: true,
      requests: requests,
      device: device
    };
  };

  // Function to handle the API call
  const submits2sauth = async () => {
    const authorization = localStorage.getItem('token') || null;

    const requestData = buildRequestData();

    const response = await fetch(`https://api.bucksburst.com/open_s2s_auth_link`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authorization}`
        },
        credentials: 'include',
        body: JSON.stringify(requestData)
    });

    const data = await response.json();
    setJsonResponse(data);
  };

  // Run the API call on mount
  useEffect(() => {
    submits2sauth();
  }, []);

  const reorderSingleTransaction = (data) => {
    return {
      adid: data.error_details.adid,
      app_token: data.error_details.app_token,
      error: data.error_details.error,
      status_code: data.status_code,
      task: data.task || 'None'  // Check if task exists, otherwise show 'None'
    };
  };
  
  // Reorder the fields for multiple transactions
  const reorderMultipleTransactions = (responses) => {
    return responses.map((response) => ({
      adid: response.error_details.adid,
      app_token: response.error_details.app_token,
      error: response.error_details.error,
      status_code: response.status_code,
      task: response.task || 'None'  // Check if task exists, otherwise show 'None'
    }));
  };

  return (
    <div className={styles.container}>
      <div className="header">
      <div className="header-frame">
            Adjust/S2S Converter by W_Ducky
        </div>
        
      </div>
      {jsonResponse ? (
        <div>
          {jsonResponse.multiple ? (
            <div>
              <table className={styles.table}>
                <tbody>
                  {reorderMultipleTransactions(jsonResponse.responses).map((response, index) => (
                    <tr key={index} className={styles.tableRow}>
                      <td className={styles.tableCell}>
                        <SyntaxHighlighter language="json" style={solarizedDark}>
                          {JSON.stringify(response, null, 2)}
                        </SyntaxHighlighter>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <div className={styles.card}>
              <SyntaxHighlighter language="json" style={solarizedDark}>
                {JSON.stringify(reorderSingleTransaction(jsonResponse), null, 2)}
              </SyntaxHighlighter>
            </div>
          )}
        </div>
      ) : (
        <p className={styles.loading}>Loading...</p>
      )}
      <footer style={{
      backgroundColor: '#181818',
      color: '#b3b3b3',
      padding: '20px 0',
      position: 'fixed',
      bottom: 0,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontFamily: 'Arial, sans-serif',
    }}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        fontSize: '14px',
      }}>
        <a href="/privacy-policy" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
        >
          Privacy Policy
        </a>
        <span style={{
          color: '#b3b3b3',
          margin: '0 10px',
        }}>
          |
        </span>
        <a href="/terms-of-service" style={{
          color: '#b3b3b3',
          textDecoration: 'none',
          margin: '0 10px',
          transition: 'color 0.3s ease',
        }} 
        onMouseEnter={(e) => e.target.style.color = '#ffffff'}
        onMouseLeave={(e) => e.target.style.color = '#b3b3b3'}
        >
          Terms of Service
        </a>
      </div>
    </footer>
    </div>
  );
}

export default S2SAuthComponent;
